<template>
  <Default>
  </Default>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { mapState, mapGetters, mapActions } from 'vuex'

import Default from '@/layout/Default.vue'

export default defineComponent({
  meta: {
    title: `Talent`,
    meta: [{ name: 'description', content: `content` }]
  },
  name: `Talent`,
  components: {
    Default
  }
})
</script>

<style lang="scss" scoped>
</style>
